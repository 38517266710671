<template>
  <div class="teacher">
    <list-template
        :loading="loading"
      :total="total"
      :table-data="tableData"
      :table-config="tableConfig"
      :current-page="page"
      @onChangePage="changeCurrentPage"
      @onHandle="tableHandle"
      hasAdd
      @onAdd="onAdd"
    ></list-template>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading:true,
      // 表格配置
      tableConfig: [
        {
          prop: "task_cycle",
          label: "任务周期",
        },
        {
          prop: "school",
          label: "校区 ",
        },
        {
          prop: "creator",
          label: "创建人",
        },
        {
          prop: "created_at",
          label: "创建时间",
        },
        {
          label: "操作",
          handle: true,
          width: 120,
          render: (row) => {
            if (row.creator_id == this.$store.getters.userInfo.userinfo.id) {
              return ["编辑"];

            }
            return ["查看"];
          },
        },
      ],
      search: null,
      total: 0,
      dialogVisible: false,
      // 表格中的数据
      tableData: [],
    };
  },
  computed: {
    ...mapState(["page"]),
  },
  mounted() {
    this.$store.commit("setPage", 1);
    this.getData();
  },
  activated() {
    this.getData();
  },
  methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit("setPage", e);
      this.getData();
    },
    /**
     * 获取数据
     */
    getData() {
      let { search, page } = this;
      this.loading = true;
      this.$_axios2.get("api/1v1/coach", { params: { ...search, page } }).then((res) => {
        this.tableData = res.data.data.data;
        this.total = res.data.data.total;
      }).finally(()=>this.loading = false);
    },
    // 新增按钮
    onAdd() {
      this.$router.push("./add");
    },
    // 表格后面操作被点击
    tableHandle(row, handle, index) {
      if (handle.type === 'view') {
        this.$router.push({path: './edit', query: {id: row.id, type: 0}})
      }
      if (handle.type === 'edit') {
        this.$router.push({path: './edit', query: {id: row.id, type: 1}})
      }
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
.list {
  padding: 24rem 0 0 32rem;
  .search {
    .el-input,
    .el-select {
      width: 240rem;
      margin-right: 24rem;
    }
  }
  .pagination {
    margin-top: 50rem;
  }
}
</style>
